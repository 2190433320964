.eggrun-terms-text {
    & { padding: 24px; }
    h2 { padding-bottom: 16px; font-weight: 800; font-size: 20px; line-height: 130%; letter-spacing: 1px; color: $color-eggrun-title; }
    h3,
    h4 { @extend .font-helvetica-now-text; font-size: 16px; line-height: 150%; font-weight: 700; }
    p { @extend .font-helvetica-now-text; font-size: 16px; line-height: 150%; padding-bottom: 24px; }
    ul { list-style-type: none; padding-bottom: 24px; }
    li { @extend .font-helvetica-now-text; font-size: 16px; line-height: 150%; }
    table { margin: 8px 0 32px; border-collapse: collapse; border: 1px solid $color-gray-55; }
    td,
    th { border: 1px solid $color-gray-55; padding: 7px 16px; font-size: 14px; line-height: 21px; text-align: left; vertical-align: top; }
    th:last-child,
    td:last-child { white-space: nowrap; }
}