.font-helvetica {
    font-family: 'Helvetica Neue', Helvetica, arial, sans-serif;
    font-style: normal;
    font-weight: 400;
}

.font-helvetica-now-display {
    font-family: 'Helvetica Now Display', Helvetica, arial, sans-serif;
    font-style: normal;
    font-weight: 400;
}

.font-helvetica-now-text {
    font-family: 'Helvetica Now Text', Helvetica, arial, sans-serif;
    font-style: normal;
    font-weight: 400;
}
