.eggrun-play { width: 100%; height: 100%; box-sizing: border-box; display: flex; flex-flow: column nowrap; overflow: hidden; position: relative; z-index: $zIndex-view; background: $color-campaign-rose-bg-medium; }

.eggrun-play-permissions { padding: 32px 0; }
.eggrun-play-permissions-button { margin-top: auto; padding: 0 24px; }

.eggrun-play-game { }

.eggrun-play-summary-buttons { padding: 0 24px 32px; }

.eggrun-play-device-error {
    & { position: absolute; top: 0; left: 0; width: 100%; min-height: 100%; z-index: $zIndex-error-device; background: $color-eggrun-bg; display: flex; flex-flow: column nowrap; justify-content: center; align-items: center; }
    &--hide { visibility: hidden; opacity: 0; z-index: -1; }
}
.eggrun-play-device-error-title { padding: 24px 24px 16px; width: 100%; box-sizing: border-box; font-weight: 800; font-size: 20px; line-height: 130%; letter-spacing: 1px; color: $color-eggrun-title; }
.eggrun-play-device-error-text { padding: 0 24px 24px; width: 100%; box-sizing: border-box; @extend .font-helvetica-now-text; font-weight: normal; font-size: 16px; line-height: 150%; }
.eggrun-play-device-error-keyvisual {  }
.eggrun-play-device-error-button { padding: 24px; width: 100%; box-sizing: border-box; }
@media (max-width: 374px) {
    .eggrun-play-device-error-title { font-size: 18px; }
    .eggrun-play-device-error-text { font-size: 14px; padding-bottom: 24px; }
    .eggrun-play-device-error-keyvisual > div { width: 152px; height: 164px; }
}

a.eggrun-play-device-error-link {
    & { color: $color-orange; text-decoration: underline; }
    &:hover { text-decoration: none; color: $color-orange-dark; }
}
