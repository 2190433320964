.button {
    & { @extend .font-helvetica-now-display; display: inline-block; vertical-align: middle; margin: 0; border: 1px solid $color-campaign-secondary; padding: 11px 24px; height: 48px; box-sizing: border-box; background: transparent; color: $color-campaign-secondary; font-size: 16px; line-height: 24px; font-weight: 700; border-radius: 4px; text-align: center; overflow: hidden; position: relative; transition: color 0.6s ease; cursor: pointer; }
    &::after { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1; opacity: 0; transition: opacity 0.6s ease; background-color: $color-campaign-secondary; }
    span { display: inline-block; position: relative; z-index: 2; }
    .button-hover-container:hover & {
        & { color: $color-white; }
        &::after { opacity: 1; }
    }
}
a.button {
    &:focus { color: $color-campaign-secondary; }
    &:hover {
        & { color: $color-white; }
        &::after { opacity: 1; }
    }
}
@media (max-width: $size-phone-small) {
    .button { font-size: 14px; }
}