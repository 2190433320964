.eggrun-text-content {
    & { padding: 24px 24px 32px; }
    h2 { font-weight: 800; font-size: 20px; line-height: 130%; letter-spacing: 1px; color: $color-eggrun-title; }
    p { @extend .font-helvetica-now-text; font-size: 16px; line-height: 150%; padding-top: 16px; }
    ul { list-style-type: none; }
    li { @extend .font-helvetica-now-text; font-size: 16px; line-height: 150%; }
    strong { color: $color-eggrun-title; font-weight: 700; }
    b { font-weight: 700; }
    a { color: $color-eggrun-title; font-weight: 700; text-decoration: underline; }
    a:hover { text-decoration: none; }
}
.eggrun-text-content-image {
    & { display: block; width: 100%; padding-bottom: 16px; box-sizing: border-box; }
    &-box { 
        & { margin: 0 24px; padding: 16px 0; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); }
        .eggrun-view-green & { background: $color-campaign-rose-bg-medium; }
        .eggrun-view-rose & { background: $color-eggrun-bg; }
    }
    img { width: 100%; vertical-align: middle; }
    &-note { display: block; padding: 8px 0 0 0; text-align: center; @extend .font-helvetica-now-text; font-size: 14px; line-height: 140%; color: $color-gray-55; }
    &-note-right { padding-right: 40px; text-align: right; }
}
.eggrun-text-content-button { padding: 0 24px; }
.eggrun-view-green {
    .eggrun-text-content:last-child { padding-bottom: 0; }
}
.eggrun-view-rose {
    .eggrun-text-content:first-child { padding-top: 0; }
}
