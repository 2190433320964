$color-eggrun-bg-desktop: #E7F5BC;
$color-eggrun-bg: #F5FAE6;
$color-eggrun-title: #7DB425;

$zIndex-rotate: 10000;
$zIndex-loading: 5000;
$zIndex-error-device: 4000;
$zIndex-tutorial: 1001;
$zIndex-tutorial-overlay: 1000;
$zIndex-error-popup: 501;
$zIndex-error-popup-overlay: 500;
$zIndex-sidebar-overlay: 100;
$zIndex-sidebar: 101;
$zIndex-sidebar-trigger: 3;
$zIndex-topbar: 2;
$zIndex-view: 1;
$zIndex-desktop: 1; 

#root {
    height: 100%;
}

.container-eggrun-bg { background: $color-eggrun-bg-desktop url('../../../assets/desktop/bg.jpg') repeat center; height: 100%; display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; }
.container-eggrun { width: 375px; height: 635px; max-height: 100%; background: $color-eggrun-bg; box-shadow: 0px 0px 48px rgba(73, 90, 22, 0.3); position: relative; display: flex; flex-flow: column nowrap; overflow: hidden; }
@media (max-width: 500px) {
    .container-eggrun { width: 100%; height: 100%; }
}
@media (max-width: 320px) {
    .container-eggrun-bg { width: 320px; }
    .container-eggrun { width: 320px; }
}

@media (min-width: 501px) and (max-height: 680px) {
    .container-eggrun { height: 520px; }
}
@media (min-width: 501px) and (max-height: 540px) {
    .container-eggrun { height: 440px; }
}

@import "./common/eggrun-text-content";
@import "./common/eggrun-buttons";
@import "./common/eggrun-points";
@import "./common/eggrun-animations";

@import "./parts/eggrun-keyvisual";
@import "./parts/eggrun-wave";
@import "./parts/eggrun-share";
@import "./parts/eggrun-footer";
@import "./parts/eggrun-topbar";

@import "./main/eggrun-desktop";
@import "./main/eggrun-sidebar";
@import "./main/eggrun-sidebar-trigger";
@import "./main/eggrun-rotate";
@import "./main/eggrun-loading";
@import "./main/eggrun-error-popup";

@import "./eggrun-views";

