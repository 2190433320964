.Hud {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .points {
        background: white;
        box-shadow: 0px 2.5px 10px rgba(41, 102, 5, 0.1);
        border-radius: 0 0 24px 24px;
        padding: 0.44em 33px;
        text-align: center;
        flex-grow: 0;
        min-width: 79px;
        position: relative;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 350px) {
            padding: 0.44em 13px;
        }

        h4 {
            margin: 0;
            font-family: 'Helvetica Now Text', Helvetica, arial, sans-serif;
            font-weight: bold;
            font-style: normal;
            font-size: 8px;
            line-height: 140%;
            text-align: center;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-feature-settings: 'cpsp' on;
            color: #333333;
            margin-bottom: 2px;
        }

        span {
            font-size: 2em;
            font-family: 'Helvetica Now Text', Helvetica, arial, sans-serif;
            font-weight: 800;
            font-style: normal;
            font-size: 24px;
            line-height: 130%;
            text-align: center;
            letter-spacing: -1px;
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: #ff6600;
        }

        .modifier {
            position: absolute;
            bottom: 0em;
            right: -1em;
            width: 2.3em;
            height: 2.3em;
            background: #fcece4;
            border-radius: 50%;
            font-family: Helvetica Now Text, Helvetica, arial, sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 130%;
            text-align: center;
        }
    }

    .challengeScore {
        display: inline-block;
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        padding: 8px 16px;

        h4 {
            margin: 0;
            font-family: 'Helvetica Now Text', Helvetica, arial, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 8px;
            line-height: 140%;
            text-align: center;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-feature-settings: 'cpsp' on;
            color: #fff;
            margin-bottom: 4px;
        }

        span {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            border: 3px solid #fcece4;
            border-radius: 20px;
            color: #ff6600;
            min-width: 64px;
            font-family: 'Helvetica Now Display', Helvetica, arial, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            font-feature-settings: 'tnum' on, 'lnum' on;
        }
    }

    .timer {
        display: inline-block;
        text-align: center;
        position: absolute;
        right: 0;
        top: 0;
        padding: 8px 16px;

        h4 {
            margin: 0;
            font-family: 'Helvetica Now Text', Helvetica, arial, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 8px;
            line-height: 140%;
            text-align: center;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-feature-settings: 'cpsp' on;
            color: #fff;
            margin-bottom: 4px;
        }

        span {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #7db425;
            border: 3px solid #aac29b;
            border-radius: 20px;
            color: white;
            min-width: 64px;
            font-family: 'Helvetica Now Display', Helvetica, arial, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            font-feature-settings: 'tnum' on, 'lnum' on;
        }
    }
}

@keyframes fade-opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.75;
    }
}

.OrientationOverlay {
    background: rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    color: white;
    animation: fade-opacity 0.5s;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
        padding: 1em;
        font-family: 'Helvetica Now Display', Helvetica, arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 130%;

        text-align: center;
        letter-spacing: 1px;
        font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on, 'ss05' on, 'ss06' on, 'ss07' on;
    }

    .box {
        position: relative;
        width: 80vmin;
        height: 80vmin;
    }

    .target {
        width: 8vmin;
        height: 8vmin;
        border-radius: 50%;
        box-sizing: border-box;
        border: 2px solid #ff6600;
        position: absolute;
        top: calc(50% - 4vmin);
        left: calc(50% - 4vmin);

        &.green {
            border-color: #7db425;
        }
    }

    .current {
        width: 6vmin;
        height: 6vmin;
        border-radius: 50%;
        border: 1px solid currentColor;
        position: absolute;
        margin: -3vmin 0 0 -3vmin;
        z-index: 10;
    }

    .countdown {
        position: absolute;
        font-size: 60vh;

        opacity: 0.5;
    }
}

// development only
.controls {
    position: absolute;
    bottom: 1em;
    left: 1em;
    right: 1em;
    padding: 1em;
    display: flex;
    justify-content: center;
    flex-direction: column;

    > div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    button {
        pointer-events: all;
        background: #ff6600;
        color: white;
        border: 0;
        border-radius: 4px;
        margin: 2px;
        padding: 6px 10px;
        font-size: 1em;

        &[disabled] {
            background: darkgray;
        }

        &.btnInactive {
            background-color: grey;
        }
    }
}

.physicsBooster {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background: #fcece4;
    border-radius: 50%;

    &Icon {
        padding: 10px;
    }

    + .physicsBooster {
        right: 5rem;
    }
}

.arrow {
    position: absolute;
    z-index: 99;
    top: 30%;

    &[data-direction='left'] {
        left: 1em;

        transform: rotateZ(180deg);
        transform-origin: center;
    }
    &[data-direction='right'] {
        right: 1em;
    }

    img {
        width: 60px;
    }
}
