.eggrun-button { display: block; margin-top: 16px; height: 48px; box-sizing: border-box; line-height: 42px; border: 3px solid $color-white; background-color: $color-white; padding: 0 25px; box-shadow: 0px 2.5px 10px 1px rgba(41, 102, 5, 0.1); border-radius: 18px; font-weight: bold; font-size: 16px; color: $color-orange; cursor: pointer; position: relative; transition: color 0.4s ease, background-color 0.4s ease, border-color 0.4s ease; }
.eggrun-button:hover { color: $color-orange-dark; }
.eggrun-button-arrow {
    & { position: absolute; top: 12px; right: 25px; width: 18px; height: 18px; }
    path { fill: $color-orange; }
}
.eggrun-button-number {
    & { position: absolute; top: 9px; right: 25px; width: 24px; height: 24px; border-radius: 50%; background-color: $color-orange; line-height: 24px; color: $color-white; text-align: center; font-size: 14px; font-weight: 800; }
}
.eggrun-button.eggrun-button--primary {
    & { background-color: $color-orange; border-color: $color-orange; color: $color-white; }
    .eggrun-button-arrow path { fill: $color-white; }
    &:hover { background-color: $color-orange-dark; border-color: $color-orange-dark; color: $color-white; }
}
.eggrun-button.eggrun-button--secondary {
    & { background: linear-gradient(0deg, rgba(255, 102, 0, 0.04), rgba(255, 102, 0, 0.04)), #FFFFFF; border-color: $color-orange; color: $color-orange; }
    &:hover { background: linear-gradient(0deg, rgba(255, 61, 0, 0.04), rgba(255, 61, 0, 0.04)), #FFFFFF; border-color: $color-orange-dark; color: $color-orange-dark; }
}
.eggrun-button--disabled {
    & { color: $color-eggrun-title; cursor: default; }
    &:hover { color: $color-eggrun-title; }
}
.eggrun-button--center { text-align: center; }
.eggrun-buttons-double {
    & { display: flex; flex-flow: row nowrap; justify-content: space-between; margin-top: 16px; }
    .eggrun-button { margin-top: 0; width: auto; flex: 1 1 0; text-align: center; padding: 0; }
    .eggrun-button:first-child { margin-right: 18px; }
    .eggrun-button:last-child { margin-left: 18px; }
}
.eggrun-button:first-child,
.eggrun-buttons-double:first-child { margin-top: 0; }

