.OsternGameContainer {
  display: flex;
  background-color: #f2f2f2;
  height: 100%;
  width: 100%;
  position: relative;
}

.alertContainer {
  display: flex;
  padding: 10px;
  flex-direction: column;
}

.wrapper {
  display: block;
  width: 100%;
}

.play_button {
  margin-top: 30px;
  text-align: center;

  a {
    width: auto;
    display: inline-block;
    padding-right: 55px;
  }
}

.controls {
  padding: 1em;
  display: flex;
  justify-content: center;
  flex-direction: column;

  > p {
      text-align: center;
      margin-bottom: 10px;
  }

  > div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
  }

  button {
      pointer-events: all;
      background: #ff6600;
      color: white;
      border: 0;
      border-radius: 4px;
      margin: 4px;
      padding: 10px 18px;
      font-size: 1.5em;
      font-weight: bold;
      width: 45%;

      &[disabled] {
          background: darkgray;
      }

      &.btnInactive {
          background-color: grey;
      }
  }
}
