.eggrun-points-score {
    & { text-align: center; }
    &-number { font-weight: 800; font-size: 64px; line-height: 80px; color: $color-eggrun-title; }
    &-label { @extend .font-helvetica-now-text; font-size: 14px; line-height: 20px; }
}
.eggrun-points-stats { padding: 28px 24px 20px; }
.eggrun-points-stats-row {
    & { display: flex; flex-flow: row nowrap; align-items: center; line-height: 22px; font-weight: bold; font-size: 16px; padding-top: 8px; }
    &:first-child { padding-top: 0; }
}
.eggrun-points-stats-label {  }
a.eggrun-points-stats-link {
    & { display: block; margin-left: 6px; color: $color-orange; text-decoration: underline; }
    &:hover { text-decoration: none; color: $color-orange-dark; }
}
.eggrun-points-stats-data { margin-left: auto; text-align: right; color: $color-eggrun-title; }