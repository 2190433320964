.eggrun-wave { flex: 0 0 100px; height: 100px; position: relative; overflow: hidden; }
.eggrun-wave-shape { position: absolute; left: 0; width: 100%; height: 80px; }
.eggrun-wave--top {
    .eggrun-wave-shape { top: 0; background: transparent url('../../../wave/top.svg') no-repeat left bottom; background-size: cover; }
    .eggrun-wave-egg-1 { position: absolute; top: 12px; right: -10px; width: 79px; height: 85px; transform: rotate(180deg); background: transparent url('../../../eggs-small/left.png') no-repeat center center; background-size: 100%; }
    .eggrun-wave-egg-2 { position: absolute; top: 9px; left: 30%; width: 72px; height: 70px; transform: rotate(180deg); background: transparent url('../../../eggs-small/middle.png') no-repeat center center; background-size: 100%; }
    .eggrun-wave-egg-3 { position: absolute; top: 18px; left: -15px; width: 60px; height: 79px; transform: rotate(180deg); background: transparent url('../../../eggs-small/right.png') no-repeat center center; background-size: 100%; }
}
.eggrun-wave--bottom {
    .eggrun-wave-shape { bottom: 0; background: transparent url('../../../wave/bottom.svg') no-repeat left bottom; background-size: cover; }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .eggrun-wave--top {
        .eggrun-wave-egg-1 { background-image: url('../../../eggs-small/left@2x.png'); }
        .eggrun-wave-egg-2 { background-image: url('../../../eggs-small/middle@2x.png'); }
        .eggrun-wave-egg-3 { background-image: url('../../../eggs-small/right@2x.png'); }
    }
}