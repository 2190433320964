.videoContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    will-change: transform;
    transform: scaleX(1);
    transition: transform 0.3s ease;

    &.inverted {
        transform: scaleX(-1);
    }

    video {
        object-fit: cover;
    }
}
