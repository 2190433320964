.element-text-headline-xl { font-weight: 800; font-size: 56px; line-height: 120%; /* or 67px */ }
.element-text-headline-l { font-weight: 800; font-size: 44px; line-height: 130%; /* or 57px */ }
.element-text-headline-m { font-weight: 800; font-size: 40px; line-height: 140%; /* or 56px */ }
.element-text-headline-s { font-weight: 800; font-size: 28px; line-height: 140%; /* or 39px */ }
.element-text-headline-xs { font-weight: 700; font-size: 24px; line-height: 140%; /* or 34px */ }
.element-text-headline-xxs { font-weight: 700; font-size: 18px; line-height: 140%; /* or 25px */ }
.element-text-headline-xxxs { font-weight: 800; font-size: 14px; line-height: 140%; /* or 20px */ }
.element-text-subheadline-m { font-weight: 400; font-size: 24px; line-height: 150%; /* or 36px */ }
.element-text-subheadline-s { font-weight: 400; font-size: 22px; line-height: 140%; /* or 31px */ }
.element-text-copy-l { @extend .font-helvetica-now-text; font-weight: 400; font-size: 20px; line-height: 160%; /* or 32px */ }
.element-text-copy-m { @extend .font-helvetica-now-text; font-weight: 400; font-size: 18px; line-height: 160%; /* or 29px */ }
.element-text-copy-s { @extend .font-helvetica-now-text; font-weight: 400; font-size: 16px; line-height: 140%; /* or 22px */ }
.element-text-label { font-weight: 700; font-size: 14px; line-height: 24px; }
.element-tagline { @extend .element-text-label; color: $color-campaign-secondary; }
@media (max-width: $size-tablet-ver) {
    .element-text-headline-xl { font-size: 44px; line-height: 130%; /* or 57px */ }
    .element-text-headline-l { font-size: 40px; line-height: 130%; /* or 52px */ }
    .element-text-headline-m { font-size: 32px; line-height: 140%; /* or 45px */ }
    .element-text-headline-s { font-size: 28px; line-height: 140%; /* or 39px */ }
    .element-text-headline-xs { font-size: 22px; line-height: 140%; /* or 31px */ }
    .element-text-headline-xxs { font-size: 18px; line-height: 140%; /* or 25px */  }
    .element-text-subheadline-m { font-size: 24px; line-height: 150%; /* or 36px */ }
    .element-text-subheadline-s { font-size: 18px; line-height: 140%; /* or 25px */ }
    .element-text-copy-l { font-size: 18px; line-height: 160%; /* or 29px */ }
    .element-text-copy-m { font-size: 16px; line-height: 150%; /* or 24px */ }
    .element-text-copy-s { font-size: 14px; line-height: 140%; /* or 20px */ }
    .element-text-label { font-weight: 700; font-size: 14px; line-height: 24px; }
}
@media (max-width: $size-phone-hor) {
    .element-text-headline-xl { font-size: 34px; line-height: 130%; /* or 44px */ }
    .element-text-headline-l { font-size: 30px; line-height: 130%; /* or 39px */ }
    .element-text-headline-m { font-size: 28px; line-height: 140%; /* or 39px */ }
    .element-text-headline-s { font-size: 22px; line-height: 130%; /* or 29px */ }
    .element-text-headline-xs { font-size: 18px; line-height: 140%; /* or 25px */ }
    .element-text-headline-xxs { font-size: 14px; line-height: 140%; /* or 22px */ }
    .element-text-subheadline-m { font-size: 20px; line-height: 150%; /* or 30px */ }
    .element-text-subheadline-s { font-size: 18px; line-height: 150%; /* or 27px */ }
    .element-text-copy-l { font-size: 18px; line-height: 160%; /* or 29px */ }
    .element-text-copy-m { font-size: 16px; line-height: 150%; /* or 24px */ }
    .element-text-copy-s { font-size: 14px; line-height: 140%; /* or 20px */ }
    .element-text-label { font-weight: 700; font-size: 14px; line-height: 24px; }
}
