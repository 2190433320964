@keyframes animateScale {
    from { transform: scale(0); }
    to { transform: scale(1); }
}
@keyframes animateOpacity {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes animateOpacityReverse {
    from { opacity: 1; }
    to { opacity: 0; }
}

.eggrun-animation-win {
    & { width: 100%; height: 0; padding-bottom: 56.266666666%; position: relative; } // 375 x 211 px
    .animation-win-rays { position: absolute; top: 0; left: 0; height: 100%; width: auto; animation: animateScale 0.4s ease-in 0.8s 1 both; }
    .animation-win-egg-opponent { position: absolute; left: 55.2%; top: 26.54028436%; width: 29.333333333%; height: 39.81042654%; animation: animateOpacity 0.3s ease-in 0.7s 1 both; }
    .animation-win-egg-my { position: absolute; left: 15.7333333333%; top: 5.687203791%; width: 31.466666666%; height: 86.729857819%; animation: animateScale 0.6s cubic-bezier(.78,.01,.54,1.95) 0.5s 1 both; transform-origin: center bottom; }
    .animation-win-crown-back { position: absolute; top: 0; left: 16.8%; width: 34.666666666%; height: 39.81042654%; animation: animationWinCrown 0.3s cubic-bezier(.78,.01,.54,1.95) 0.9s 1 both; transform-origin: center bottom; }
    .animation-win-crown-front { position: absolute; top: 0; left: 16%; width: 35.4666666667%; height: 56.872037914%; animation: animationWinCrown 0.3s cubic-bezier(.78,.01,.54,1.95) 0.9s 1 both; transform-origin: center bottom; }
}
@keyframes animationWinCrown {
    0% { transform: translate(0, -20px); opacity: 0; }
    100% { transform: translate(0, 0); opacity: 1; }
}

.eggrun-animation-lost {
    & { width: 100%; height: 0; padding-bottom: 56.266666666%; position: relative; } // 375 x 211 px
    .demo-image { position: absolute; top: 0; left: 0; width: 100%; opacity: 0; z-index: 1; }
    .animation-lost-rays { position: absolute; top: 0; right: -20%; height: 100%; width: auto; animation: animateScale 0.4s ease-in 0.7s 1 both; }
    .animation-lost-egg-opponent { position: absolute; left: 64.8%; top: 13.7440758294%; width: 22.1333333333%; height: 56.8720379147%; animation: animateOpacity 0.5s ease-in 0.5s 1 both; }
    .animation-lost-egg-my { position: absolute; left: 12%; top: 1.89573459716%; width: 30.6666666667%; height: 80.0947867299%; transform: rotate(110deg); animation: animationLostEggMy 1s cubic-bezier(.7,0,.56,1.21) 0.5s 1 both; transform-origin: 50% 60%; }
    .animation-lost-egg-shadow { position: absolute; left: 17.3333333333%; top: 69.1943127962%; width: 33.6%; height: 9.00473933649%; animation: animationLostEgShadow 1s cubic-bezier(.7,0,.56,1.21) 0.5s 1 both; }
}
@keyframes animationLostEgShadow {
    0% { transform: scale(0.5) translate(-40%, 60%); opacity: 0; }
    50% { transform: scale(0.5) translate(-40%, 60%); opacity: 1; }
    100% { transform: scale(1); }
}
@keyframes animationLostEggMy {
    0% { transform: scale(0); }
    50% { transform: scale(1); }
    100% { transform: scale(1) rotate(110deg); }
}

.eggrun-animation-challenge-overlay { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); z-index: 1000; animation: animateOpacityReverse 0.2s ease-in 1.5s 1 both; }
.eggrun-animation-challenge { 
    & { position: absolute; top: 50%; left: 24px; right: 24px; transform: translate(0, -50%); z-index: 1001; border-radius: 50%; overflow: hidden; animation: animateOpacityReverse 0.2s ease-in 1.5s 1 both; }
    .animation-challenge-circle { width: 100%; animation: animateScale 0.3s ease-in 0.2s 1 both; }
    .animation-challenge-vs { position: absolute; top: 50%; left: 50%; width: 135px; height: 94px; margin: -47px 0 0 -68px; animation: animateScale 0.3s ease-in 0.4s 1 both; z-index: 1; }
    .animation-challenge-left-box { position: absolute; top: 0; left: -2px; width: 100%; height: calc(50% - 5px); transform: rotate(-29.5deg);  transform-origin: center bottom; overflow: hidden; }
    .animation-challenge-left { position: absolute; left: 20px; bottom: -44px; width: 197px; height: 179px; transform: rotate(29.5deg); animation: animationSpoonLeft 0.5s cubic-bezier(.07,.59,0,1.2) 0.5s 1 both; }
    .animation-challenge-right-box { position: absolute; bottom: 0; left: 3px; width: 100%; height: calc(50% - 6px); transform: rotate(-29.5deg);  transform-origin: center top; overflow: hidden; }
    .animation-challenge-right { position: absolute; right: 20px; top: -56px; width: 208px; height: 199px; transform: rotate(29.5deg); animation: animationSpoonRight 0.5s cubic-bezier(.07,.59,0,1.2) 0.5s 1 both; }
}
@keyframes animationSpoonLeft {
    0% { transform: translate(-120%, 0) rotate(29.5deg); }
    100% { transform: translate(0, 0) rotate(29.5deg); }
}
@keyframes animationSpoonRight {
    0% { transform: translate(120%, 0) rotate(29.5deg); }
    100% { transform: translate(0, 0) rotate(29.5deg); }
}
