.message {
    &Container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
    }

    background: #fff;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
    border-radius: 18px;
    padding: 6px 9px;
    font-family: 'Helvetica Now Display', Helvetica, arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 110%;
    display: flex;
    align-items: center;
    text-align: center;
    max-width: 198px;
}
