.animationContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.animation {
    position: relative;
    overflow: hidden;
    will-change: transform;

    // mirrored version for wind
    &[data-direction='right'] {
        transform: scale(-1);
    }

    &::before {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        height: inherit;
        will-change: transform;
    }
}

// Countdown (minimal)
.countdownAnimation {
    width: 480px;
    height: 320px;

    &::before {
        width: 2880px;
        animation: countdownAnimation 4s steps(5) infinite;
        animation-play-state: paused;
        background-image: url(../../assets/animations/countdown-minimal.png);
    }
}
@keyframes countdownAnimation {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-2400px);
    }
}

/**
 * Booster (2x, 3x, 4x, 100p, 500p)
 * All have the same base animation 10fps and an overlay image.
 * The overlay should be displayed at frame 6 (scaled 80%)
 * scaled to 100% until frame 22 and disappear after frame 22.
 */
.boosterAnimation {
    width: 480px;
    height: 320px;
    z-index: 5;

    &::before {
        width: 12960px;
        animation: boosterAnimation 2.7s steps(26) infinite;
        background-image: url(../../assets/animations/booster-animation.png);
        animation-play-state: paused;
    }

    &::after {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        height: inherit;
        width: inherit;
        will-change: transform, opacity;
        transform: scale(0.8);
        opacity: 0;

        animation-name: boosterOverlayAnimation;
        animation-duration: 1.9s; // 2 extra frames to fade in/out
        animation-fill-mode: forwards; // keep last state
        animation-delay: 0.5s; // start at the 6th frame
        animation-play-state: paused;
    }
}
.boosterAnimation100p {
    &::after {
        background-image: url(../../assets/animations/100points.png);
    }
}
.boosterAnimation500p {
    &::after {
        background-image: url(../../assets/animations/500points.png);
    }
}
.boosterAnimation2x {
    &::after {
        background-image: url(../../assets/animations/points_x2.png);
    }
}
.boosterAnimation3x {
    &::after {
        background-image: url(../../assets/animations/points_x3.png);
    }
}
.boosterAnimation4x {
    &::after {
        background-image: url(../../assets/animations/points_x4.png);
    }
}

@keyframes boosterAnimation {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-12480px);
    }
}
@keyframes boosterOverlayAnimation {
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    6% {
        // fade in within ~0.1s
        transform: scale(0.8);
        opacity: 1;
    }
    95% {
        // scale up
        transform: scale(1);
        opacity: 1;
    }
    to {
        // fade out
        transform: scale(1);
        opacity: 0;
    }
}

// Week 1: Wind
.windAnimation {
    width: 352px;
    height: 554px;

    &::before {
        width: 17600px;
        animation: windAnimation 5s steps(49) infinite;
        animation-play-state: paused;
        background-image: url(../../assets/animations/wind-full.png);
    }
}
@keyframes windAnimation {
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(-17248px);
    }
}

// Week 2: Obstruct
.obstructAnimation {
    width: 480px;
    height: 854px;
    margin-top: calc(50vh - (852px / 2));

    &::before {
        width: 24000px;
        animation: obstructAnimation 5s steps(50) infinite;
        animation-play-state: paused;
        background-image: url(../../assets/animations/sun.png);
    }
}
@keyframes obstructAnimation {
    from {
        left: 0px;
    }
    to {
        left: -24000px;
    }
}

// utils
.stopAnimation {
    &::before,
    &::after {
        animation-play-state: paused;
        animation: unset;
    }
}
.playAnimation {
    &::before,
    &::after {
        animation-play-state: running;
    }
}
