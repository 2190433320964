.container-home-stories { background: $color-white; padding: 64px 0 72px; }
.home-stories { width: 1216px; margin: 0 auto; }
.home-stories-tagline { @extend .element-tagline; }
.home-stories-title { @extend .element-text-headline-m; padding-bottom: 64px; }
.home-stories-more { text-align: center; padding-top: 56px; }
.home-stories-grid { display: grid; grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(2, 1fr); grid-column-gap: 32px; grid-row-gap: 32px; }
.home-stories-item {
    & { display: block; width: 100%; background: $color-white; box-sizing: border-box; border-radius: 4px; box-shadow: 0px 4px 24px rgba(9, 18, 50, 0.2); transition: box-shadow 0.6s ease, transform 0.6s ease; position: relative; overflow: hidden; }
    &-thumb {
        & { position: relative; }
        .home-stories-item--small & { height: 100%; }
    }
    &-image {
        & { width: 100%; vertical-align: middle; object-fit: cover; }
        .home-stories-item--small & { height: 100%; }
    }
    &-logo { position: absolute; top: 16px; right: 16px; width: 107px; }
    &-content {
        .home-stories-item--small & { position: absolute; bottom: 0; left: 0; width: 100%; padding: 24px; box-sizing: border-box; background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(14, 15, 13, 0.97) 100%); }
        .home-stories-item--big & { padding: 32px; }
    }
    &-tagline { @extend .element-tagline; }
    &--small &-tagline { color: $color-campaign-primary; }
    &-title {
        @extend .element-text-headline-xxs; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
        @supports (-webkit-line-clamp: 2) { overflow: hidden; text-overflow: ellipsis; white-space: initial; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }
        .home-stories-item--small & { color: $color-white; }
    }
}
@media (hover: hover) {
    .home-stories-item {
        &:hover { box-shadow: 0px 13px 30px rgba(9, 18, 50, 0.2); transform: translate(0, -4px); }
    }
}
.home-stories-grid-3 {
    .home-stories-item {
        &-1 { grid-area: 1 / 1 / 3 / 2; }
        &-2 { grid-area: 1 / 2 / 3 / 3; }
        &-3 { grid-area: 1 / 3 / 3 / 4; }
    }
}
.home-stories-grid-4 {
    .home-stories-item {
        &-1 { grid-area: 1 / 1 / 3 / 2; }
        &-2 { grid-area: 1 / 2 / 3 / 3; }
        &-3 { grid-area: 1 / 3 / 2 / 4; }
        &-4 { grid-area: 2 / 3 / 3 / 4; }
    }
}
.home-stories-grid-5 {
    .home-stories-item {
        &-1 { grid-area: 1 / 1 / 3 / 2; }
        &-2 { grid-area: 1 / 2 / 2 / 3; }
        &-3 { grid-area: 2 / 2 / 3 / 3; }
        &-4 { grid-area: 1 / 3 / 2 / 4; }
        &-5 { grid-area: 2 / 3 / 3 / 4; }
    }
}
.home-stories-grid-6 {
    .home-stories-item {
        &-1 { grid-area: 1 / 1 / 2 / 2; }
        &-2 { grid-area: 2 / 1 / 3 / 2; }
        &-3 { grid-area: 1 / 2 / 2 / 3; }
        &-4 { grid-area: 2 / 2 / 3 / 3; }
        &-5 { grid-area: 1 / 3 / 2 / 4; }
        &-6 { grid-area: 2 / 3 / 3 / 4; }
    }
}
@media (max-width: $size-tablet-hor) {
    .home-stories { width: 940px; }
    .home-stories-grid { grid-column-gap: 20px; grid-row-gap: 20px; }
    .home-stories-item {
        &-content {
            .home-stories-item--big & { padding: 24px; }
        }
    }
}
@media (max-width: $size-tablet-ver) {
    .container-home-stories { padding: 56px 0 64px }
    .home-stories { width: 704px; }
    .home-stories-title { padding-bottom: 48px; }
    .home-stories-grid { display: grid; grid-template-columns: repeat(2, 1fr); grid-template-rows: repeat(3, 1fr); grid-column-gap: 16px; grid-row-gap: 16px; }
    .home-stories-grid-3 {
        & { grid-template-columns: repeat(2, 1fr); grid-template-rows: repeat(2, 1fr); }
        .home-stories-item {
            &-1 { grid-area: 1 / 1 / 2 / 2; }
            &-2 { grid-area: 1 / 2 / 2 / 3; }
            &-3 { grid-area: 2 / 1 / 3 / 2; }
        }
    }
    .home-stories-grid-4 {
        .home-stories-item {
            &-1 { grid-area: 1 / 1 / 3 / 2; }
            &-2 { grid-area: 1 / 2 / 3 / 3; }
            &-3 { grid-area: 3 / 1 / 4 / 2; }
            &-4 { grid-area: 3 / 2 / 4 / 3; }
        }
    }
    .home-stories-grid-5 {
        .home-stories-item {
            &-1 { grid-area: 1 / 1 / 3 / 2; }
            &-2 { grid-area: 1 / 2 / 2 / 3; }
            &-3 { grid-area: 2 / 2 / 3 / 3; }
            &-4 { grid-area: 3 / 1 / 4 / 2; }
            &-5 { grid-area: 3 / 2 / 4 / 3; }
        }
    }
    .home-stories-grid-6 {
        .home-stories-item {
            &-1 { grid-area: 1 / 1 / 2 / 2; }
            &-2 { grid-area: 1 / 2 / 2 / 3; }
            &-3 { grid-area: 2 / 1 / 3 / 2; }
            &-4 { grid-area: 2 / 2 / 3 / 3; }
            &-5 { grid-area: 3 / 1 / 4 / 2; }
            &-6 { grid-area: 3 / 2 / 4 / 3; }
        }
    }
}
@media (max-width: $size-phone-hor) {
    .container-home-stories { padding: 72px 24px 64px }
    .home-stories { width: auto; }
    .home-stories-title { padding-bottom: 32px; }
    .home-stories-grid { display: block; }
    .home-stories-item {
        & + & { margin-top: 32px; }
    }
}
