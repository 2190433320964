$color-white: #fff;
$color-black: #000;

$color-gray-33: #333;
$color-gray-55: #555;
$color-gray-55-10: rgba(85, 85, 85, 0.1);
$color-gray-ca: #CACACA;
$color-gray-f1: #F1EFEE;

$color-gray-text: $color-gray-33;

$color-orange: #F60;
$color-orange-20: rgba(255, 102, 0, 0.2);
$color-orange-dark: #DE450A;

// Ostern
$color-campaign-primary: #AED76C;
$color-campaign-secondary: #589842;

$color-campaign-apricot: #E66C64;
$color-campaign-rose: #FFECD6;
$color-campaign-rose-bg: #FFF9F6;
$color-campaign-rose-bg-medium: #FFF5F0;
$color-campaign-rose-bg-dark: #FFF3ED;
$color-campaign-rose-bg-darker: #FCECE4;
