@keyframes keyvisualEggLeft {
    50% { transform: rotate(-3deg); }
}
@keyframes keyvisualEggRight {
    50% { transform: rotate(3deg); }
}
@keyframes keyvisualEggFront {
    50% { transform: translate(0, -2.469135802%); } // -6px
}
@keyframes keyvisualSpoon1 {
    50% { transform: rotate(3deg); }
}
@keyframes keyvisualSpoon2 {
    0% { transform: rotate(-90deg); }
    50% { transform: rotate(-93deg); }
    100% { transform: rotate(-90deg); }
}

.eggrun-keyvisual {
    & { width: 225px; height: 243px; position: relative; margin: 0 auto; }
    &--small { width: 152px; height: 164px; }
}
.eggrun-keyvisual-spoon {
    & { position: absolute; top: 8%; left: 0; width: 100%; height: 93.004115226%; z-index: 5; background: transparent url('../../../keyvisual/spoon.png') no-repeat center center; background-size: 100%; }
    &-1 { animation: keyvisualSpoon1 4s ease-in-out 1s infinite both; }
    &-2 { transform: rotate(-90deg); animation: keyvisualSpoon2 4s ease-in-out 1s infinite both; }
}
.eggrun-keyvisual-egg-left { position: absolute; top: 34.567901234%; left: 6.666666666%; width: 36.444444444%; height: 44.03292181%; z-index: 1; animation: keyvisualEggLeft 4s ease-in-out 0s infinite both; transform-origin: center bottom; background: transparent url('../../../keyvisual/egg-left.png') no-repeat center center; background-size: 100%; }
.eggrun-keyvisual-egg-right { position: absolute; top: 34.567901234%; right: 6.222222222%; width: 37.333333333%; height: 44.855967078%; z-index: 1; animation: keyvisualEggRight 4s ease-in-out 0s infinite both; transform-origin: center bottom; background: transparent url('../../../keyvisual/egg-right.png') no-repeat center center; background-size: 100%; }
.eggrun-keyvisual-egg-front { position: absolute; top: 9.876543209%; left: 19.111111111%; width: 61.333333333%; height: 82.716049382%; z-index: 10; animation: keyvisualEggFront 4s ease-in-out 0s infinite both; background: transparent url('../../../keyvisual/egg-front.png') no-repeat center center; background-size: 100%; }
.eggrun-keyvisual-text-top { position: absolute; top: 4.115226337%; left: 31.555555555%; width: 37.333333333%; height: 8.641975308%; z-index: 20; }
.eggrun-keyvisual-text-bottom { position: absolute; bottom: 4.115226337%; left: 37.777777777%; width: 24.444444444%; height: 4.44444444444%; z-index: 20; }
.eggrun-keyvisual-text-center { 
    & { position: absolute; top: 31.275720164%; left: 0; width: 100%; height: 45.267489711%; z-index: 20; }
    &-fr,
    &-it { top: 21.8106995885%; height: 54.7325102881%; }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .eggrun-keyvisual-spoon { background-image: url('../../../keyvisual/spoon@2x.png'); }
    .eggrun-keyvisual-egg-left { background-image: url('../../../keyvisual/egg-left@2x.png'); }
    .eggrun-keyvisual-egg-right { background-image: url('../../../keyvisual/egg-right@2x.png'); }
    .eggrun-keyvisual-egg-front { background-image: url('../../../keyvisual/egg-front@2x.png'); }
}