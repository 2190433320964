.container-home-clip { background: $color-campaign-rose-bg-dark; }
.home-clip { width: 1216px; margin: 0 auto; padding: 96px 0; display: flex; flex-flow: row nowrap; align-items: center; justify-content: space-between; }
.home-clip-content { width: 488px; order: 1; }
.home-clip-tagline { @extend .element-tagline; color: $color-campaign-apricot; }
.home-clip-title { @extend .element-text-headline-m; }
.home-clip-desc { @extend .element-text-subheadline-s; padding-top: 8px; }
.home-clip-video-box { width: 592px; height: 333px; position: relative; border-radius: 4px; order: 2; overflow: hidden; }
.home-clip-video { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
@media (max-width: $size-tablet-hor) {
    .home-clip { width: 940px; }
    .home-clip-content { width: 380px; }
    .home-clip-video-box { width: 468px; height: 264px; }
}
@media (max-width: $size-tablet-ver) {
    .home-clip { width: 704px; }
    .home-clip-content { width: 308px; }
    .home-clip-video-box { width: 344px; height: 194px; }
}
@media (max-width: $size-phone-hor) {
    .home-clip { width: auto; margin: 0 auto; padding: 72px 24px; display: block; }
    .home-clip-content { width: auto; padding-top: 40px; }
    .home-clip-video-box { width: 100%; height: 0; padding-bottom: 56.25%; }
}
