.eggrun-ranking-table {
    & { width: 100%; background: $color-campaign-rose-bg-medium; border: 0; border-collapse: collapse; }
    td,
    th {
        & { border: 0; margin: 0; padding: 0 0 0 16px; text-align: left; }
        &:last-child { text-align: right; }
    }
    .cell-rank { max-width: 80px; }
    .cell-name { max-width: calc(100vw - 216px); overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .cell-highscore { max-width: 136px; padding-right: 16px; }
    thead tr th { background: $color-eggrun-title; color: $color-white; font-weight: bold; font-size: 14px; line-height: 32px; }
    tbody tr td {
        & { border-bottom: 1px solid $color-white; background: $color-campaign-rose-bg-medium; font-size: 14px; line-height: 32px; padding-top: 8px; padding-bottom: 8px; }
        &:last-child { color: $color-orange; font-weight: 800; }
    }
    tbody tr.top td {
        & { background: $color-campaign-rose-bg-darker; }
    }
    tbody tr.current td {
        & { background: $color-orange; color: $color-white; }
        &:last-child { color: $color-white; }
    }
    tbody tr.separator td {
        & { line-height: 4px; padding: 22px 30px; }
        span { display: block; width: 100%; height: 2px; background-color: $color-orange; }
    }
}
