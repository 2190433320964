.eggrun-view { width: 100%; min-height: 100%; box-sizing: border-box; display: flex; flex-flow: column nowrap; overflow-x: hidden; overflow-y: auto; position: relative; z-index: $zIndex-view; -webkit-overflow-scrolling: touch; }
.container-eggrun--fixed .eggrun-view { overflow-y: hidden; }
.eggrun-view-rose { background: $color-campaign-rose-bg-medium; }
.eggrun-view-green { background: $color-eggrun-bg; }
.eggrun-view-orange { 
    & { @extend .font-helvetica-now-text; background: $color-orange; color: $color-white; padding: 8px 24px; font-size: 14px; line-height: 140%; }
    strong { font-weight: bolf; }
}

@import "./views/eggrun-overview";
@import "./views/eggrun-challenge";
@import "./views/eggrun-ranking";
@import "./views/eggrun-terms";
@import "./views/eggrun-play";
@import "./views/eggrun-play-tutorial";
