.container-home-offers { padding: 48px 0 72px; background: $color-campaign-primary; overflow: hidden; }
.home-offers { width: 1216px; margin: 0 auto; }
.home-offers-tagline { @extend .element-tagline; }
.home-offers-title { @extend .element-text-headline-m; padding-bottom: 24px; }
.home-offers-date { display: inline-block; margin: 0 0 20px; padding: 0 12px; background: rgba(255, 255, 255, 0.3); border-radius: 4px; font-weight: 800; font-size: 14px; line-height: 32px; }
.home-offers-slider { position: relative; }
.home-offers-disclaimer { padding-top: 36px; @extend .element-text-copy-s; text-align: center; }
@media (max-width: $size-tablet-hor) {
    .home-offers { width: 904px; }
}
@media (max-width: $size-tablet-ver) {
    .container-home-offers { padding: 36px 0 72px; }
    .home-offers { width: 704px; }
    .home-offers-date { margin: 24px 0 12px; }
    .home-offers-disclaimer { text-align: left; }
}
@media (max-width: $size-phone-hor) {
    .container-home-offers { padding: 64px 24px 72px; }
    .home-offers { width: auto; }
    .home-offers-disclaimer { padding-top: 28px; }
}
