.container-home-intro { padding: 24px 0 64px; background: $color-campaign-rose-bg; position: relative; z-index: 1; }
body.body-fixed .container-home-intro { z-index: -1; }
.home-intro { width: 1440px; margin: 0 auto; background: $color-campaign-rose-bg; height: 560px; position: relative; display: flex; flex-flow: row nowrap; align-items: center; overflow: hidden; box-shadow: 0px 12px 18px -12px rgba(16, 37, 4, 0.2); }
.home-intro-image {
    & { position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; transition: transform 1s ease; transform-origin: center center; z-index: 1; }
    &--empty { background-color: $color-gray-f1; }
}
@media (hover: hover) {
    .home-intro:hover .home-intro-image { transform: scale(1.02); }
}
.home-intro-triangle {
    & { position: absolute; top: 0; left: 25%; margin-left: -40px; width: 80px; height: 93px; z-index: 2; }
    path { fill: $color-campaign-rose-bg; }
}
.home-intro-content { padding-left: 116px; /* width: 592px; */ width: 720px; z-index: 3; position: relative; }
.home-intro-title { @extend .element-text-headline-xl; color: $color-white; text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); }
.home-intro-desc { @extend .element-text-headline-xs; padding-top: 16px; color: $color-white; text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); }
.home-intro-button {
    & { padding-top: 40px; }
    .button {
        & { color: $color-white; background-color: $color-orange; border: 0; transition: background-color 0.4s ease; box-shadow: inset 0px -2px 0px $color-orange-dark; }
        &::after { display: none; }
        .home-intro:hover & { background-color: $color-orange-dark; }
    }
}
@media (max-width: $size-desktop) {
    .home-intro { width: 1352px; }
    .home-intro-content { padding-left: 72px; }
}
@media (max-width: $size-laptop) {
    .home-intro { width: 1216px; }
    .home-intro-content { padding-lefT: 64px; }
}
@media (max-width: $size-tablet-hor) {
    .home-intro { width: 940px; height: 432px; }
    .home-intro-triangle { width: 63px; height: 73px; margin-left: 31px; }
    .home-intro-content { padding-left: 48px; /* width: 480px; */ }
    .home-intro-desc { padding-top: 12px; }
}
@media (max-width: $size-tablet-ver) {
    .container-home-intro { padding-bottom: 48px; }
    .home-intro { width: 704px; }
    .home-intro-content { padding-left: 32px; width: 400px; }
    .home-intro-desc { padding-top: 8px; }
}
@media (max-width: $size-phone-hor) {
    .container-home-intro { padding: 16px 24px 32px; }
    .home-intro {
        & { height: auto; width: 100%; flex-flow: column nowrap; }
        picture { width: 100%; }
    }
    .home-intro-image { position: static; top: auto; left: auto; width: 100%; height: auto; object-fit: fill; vertical-align: middle; }
    .home-intro-triangle { left: 50%; margin-left: -26px; width: 52px; height: 60px; }
    .home-intro-content { position: absolute; bottom: 0; left: 0; padding: 32px 24px; width: 100%; box-sizing: border-box; margin-top: auto; }
    .home-intro-desc { display: none; }
    .home-intro-button { padding-top: 24px; }
}
@media (max-width: $size-phone-small) {
    .home-intro-title { font-size: 26px; }
}