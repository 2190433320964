.eggrun-tutorial-overlay {
    & { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; outline: 0; background: rgba(0, 0, 0, 0.5); opacity: 0; visibility: hidden; transition: z-index 0.4s ease, opacity 0.4s ease, visibility 0.4s ease; }
    &--open { opacity: 1; z-index: $zIndex-tutorial-overlay; visibility: visible; }
}
.eggrun-tutorial {
    & { position: absolute; left: 0; top: 0; width: 100%; height: 100%; overflow: hidden; z-index: $zIndex-tutorial; transform: translate(0, -100%); transition: transform 0.6s ease; touch-action: none; user-select: none; }
    &--open { transform: translate(0, 0); }
}
.eggrun-tutorial-slider {
    & { position: absolute; top: 50%; left: 0; display: flex; flex-flow: row nowrap; align-items: stretch; transform: translateY(-50%); }
    &-1 { width: 100%; }
    &-2 { width: 200%; }
    &-3 { width: 300%; }
    &-4 { width: 400%; }
    &-5 { width: 500%; }
    &-6 { width: 600%; }
    &-7 { width: 700%; }
    &-8 { width: 800%; }
    &-9 { width: 900%; }
    &--transition { transition: transform 0.4s ease; }
}
.eggrun-tutorial-slide-container {
    & { padding: 0 24px; box-sizing: border-box; display: flex; flex-flow: column nowrap; }
    &-1 { width: 100%; flex: 0 0 100%; }
    &-2 { width: 50%; flex: 0 0 50%; }
    &-3 { width: 33.333333333%; flex: 0 0 33.333333333%; }
    &-4 { width: 25%; flex: 0 0 25%; }
    &-5 { width: 20%; flex: 0 0 20%; }
    &-6 { width: 16.666666666%; flex: 0 0 16.666666666%; }
    &-7 { width: 14.2857142857%; flex: 0 0 14.2857142857%; }
    &-8 { width: 12.5%; flex: 0 0 12.5%; }
    &-9 { width: 11.111111111%; flex: 0 0 11.111111111%; }
}
.eggrun-tutorial-slide { height: 100%; box-sizing: border-box; border-radius: 16px; overflow: hidden; background: $color-white; padding-bottom: 24px; display: flex; flex-flow: column nowrap; }
.eggrun-tutorial-slide-image { width: 100%; vertical-align: middle; }
.eggrun-tutorial-slide-text { padding: 16px 24px 24px; }
.eggrun-tutorial-slide-title { font-weight: 800; font-size: 20px; line-height: 130%; color: $color-eggrun-title; }
.eggrun-tutorial-slide-note { font-size: 14px; line-height: 20px; padding-top: 6px; }
.eggrun-tutorial-slide-button { padding: 0 24px 16px; margin-top: auto;  }
.eggrun-tutorial-slide-progress {
    & { display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; list-style: none;}
    li { width: 6px; height: 6px; background: $color-orange; opacity: 0.35; border-radius: 50%; margin: 0 5px; }
    li.active { width: 8px; height: 8px; opacity: 1; }
}
@media (max-width: $size-phone-small) {
    .eggrun-tutorial-slide { padding-bottom: 16px; }
    .eggrun-tutorial-slide-title { font-size: 16px; }
}