.container-home-claim { background-color: $color-campaign-primary; position: relative; }
.home-claim-image { display: block; height: 400px; margin: 0 auto; }
.home-claim { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); @extend .element-text-headline-m; width: 800px; color: $color-white; text-align: center; box-sizing: border-box; text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); }
.home-claim-triangle {
    & { position: absolute; top: 0; left: 50%; width: 48px; height: 56px; margin-left: -24px; }
    path { fill: $color-campaign-rose-bg-dark; }
}
.container-home-clip + .container-home-claim {
    .home-claim-triangle path { fill: $color-campaign-rose-bg-dark; }
}
@media (max-width: 1920px) {
    .home-claim-image { width: 100%; object-fit: cover; margin: 0; }
}
@media (max-width: $size-tablet-ver) {
    .home-claim { width: 644px; }
}
@media (max-width: $size-phone-hor) {
    .home-claim { width: 100%; margin: 0; padding: 0 24px; }
}